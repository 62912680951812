<template>
  <a-drawer
    :title="viewFormType == 0 ? '新建公告' : '修改公告'"
    :maskClosable="false"
    width="600"
    placement="right"
    :closable="false"
    @close="onClose"
    :visible="messageEditVisiable"
    style="height: calc(100% - 55px); overflow: auto; padding-bottom: 53px"
  >
    <a-form-model
      ref="ruleForm"
      :validateOnRuleChange="true"
      :model="messageForm"
      :rules="messageRules"
      :label-col="formItemLayout.labelCol"
      :wrapper-col="formItemLayout.wrapperCol"
    >
      <a-form-model-item label="标题" prop="title">
        <a-input v-model="messageForm.title" placeholder="公告标题" :rows="4" />
      </a-form-model-item>
      <a-form-model-item label="公告内容" prop="content">
        <editor name="content" :value.sync="messageForm.content" :height="300" @update-value="updateValue"></editor>
      </a-form-model-item>
      <a-form-model-item label="待发送时间" prop="sendTime">
        <a-date-picker
          v-model="messageForm.sendTime"
          format="YYYY-MM-DD HH:mm:ss"
          :show-time="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"
        />
      </a-form-model-item>
      <a-form-model-item label="附件" prop="attachment">
        <upload-enclosure
          :autoPath="true"
          module="MESSAGE_FENXIANG"
          :enclosure-list="enclosureList"
          @_change="handleUploadEnclosureChange"
        ></upload-enclosure>
      </a-form-model-item>
      <a-form-model-item label="接收人规则" prop="rules">
        <user-rule-creator
          @valueChange="rulesValueChange"
          ref="userRuleCreator"
        />
      </a-form-model-item>
    </a-form-model>

    <div class="drawer-bootom-button">
      <a-button @click="onClose" style="margin-right: 0.8rem">取消</a-button>
      <a-button @click="handleSubmit" type="primary" :loading="loading"
        >提交</a-button
      >
    </div>
  </a-drawer>
</template>

<script>
import Editor from "../../../components/editor/editor"
import moment from "moment";
import UserRuleCreator from "../../business-common/UserRuleCreator";
import UploadEnclosure from "@/components/upload-enclosure/UploadEnclosure";
import { mapState } from "vuex";
const formItemLayout = {};

export default {
  components: { UserRuleCreator, UploadEnclosure, Editor },
  data() {
    return {
      messageEditVisiable: false,
      formItemLayout,
      messageForm: {
        title: undefined,
        rules: undefined,
        content: undefined,
        sendTime: undefined,
      },
      messageRules: {
        title: [
          { required: true, message: "没有输入公告标题", trigger: "blur" },
          {
            min: 2,
            max: 50,
            message: "长度需在2到50之间",
            trigger: "blur",
          },
        ],
        rules: [
          { required: true, message: "没有输入公告规则", trigger: "blur" },
        ],
        content: [
          { required: true, message: "没有输入公告内容", trigger: "blur" },
        ],
        sendTime: [{ required: true, message: "选择待发送时间" }],
      },
      loading: false,
      backupData: undefined,
      viewFormType: 0, // 0:新建，1:修改
      enclosureList: [],
    };
  },
  methods: {
    moment,
    handleUploadEnclosureChange(files, urls) {
      this.enclosureList = files;
      let arr = [];
      if (urls.length) {
        urls.forEach((item, index) => {
          let f = files[index]
          arr.push({ url: item.filePath, filename: f.name, size: f.size });
        });
      }
      this.messageForm.attachment = JSON.stringify(arr);
    },
    onClose() {
      this.loading = false;
      this.messageEditVisiable = false;
      this.reset();
      this.$emit("close");
    },
    setForm(model, message) {
      if (model) {
        this.backupData = message;
        this.backupData.sendTime = message.sendTime?moment(message.sendTime):null;
        this.$nextTick(() => {
          if (this.$refs.userRuleCreator)
            this.$refs.userRuleCreator.setRules(this.backupData.rules);
        });
      } else {
        for (let key in this.messageForm) {
          this.messageForm[key] = undefined;
        }
        this.backupData = this.messageForm;
        if (this.$refs.userRuleCreator)
          this.$refs.userRuleCreator.setRules(null);
      }

      this.messageForm = this.backupData

      let arr = JSON.parse(this.messageForm.attachment || "[]");
      this.enclosureList = []
      arr.forEach(({ url, filename }) => {
        let file = {uid: '-1',status: 'done',name: '', url: ''};
        file.name = filename
        file.url = this.$store.state.baseinfo.filedomain + url;
        this.enclosureList.push(file);
      });
      this.messageEditVisiable = true;
      this.viewFormType = model;
    },
    rulesValueChange(s) {
      this.messageForm.rules = s;
    },
    updateValue(val, key='') {
      this.messageForm[key] = val;
    },
    handleSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let message = {
            ...this.messageForm,
            createUserId: this.currentUserId,
            type: 1,
          };
          message.sendTime = moment(message.sendTime).valueOf();
          if (this.viewFormType) {
            this.loading = true;
            this.$put("message", {
              ...message,
            })
              .then((r) => {
                this.loading = false;
                this.$message.success("编辑公告成功");
                this.messageEditVisiable = false;
                this.$emit("success");
                this.onClose();
              })
              .catch(() => {
                this.loading = false;
              });
          } else {
            this.loading = true;
            this.$post("message", {
              ...message,
            })
              .then((r) => {
                this.loading = false;
                this.$message.success("上传公告成功");
                this.messageEditVisiable = false;
                this.$emit("success");
                this.reset();
              })
              .catch(() => {
                this.loading = false;
              });
          }
        }
      });
    },
    reset() {
      if (this.backupData) {
        for (let key in this.backupData) {
          this.messageForm[key] = this.backupData[key];
        }
        this.$refs.userRuleCreator.setRules(null);
      }
    },
    clear() {
      for (let key in this.messageForm) {
        this.messageForm[key] = undefined;
      }
    },
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.account.user,
    }),
    currentUserId() {
      return this.currentUser.userId;
    },
  },
  watch: {
    "messageForm.rules"() {
      // alert("---" + this.messageForm.rules);
    },
  },
};
</script>